import Layout from '../components/Layout';
import ImageWrapper from '../components/ImageWrapper';
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button } from '@material-ui/core';
import { marked } from 'marked';
import './me.scss';

interface Props {
  data: any;
}

const BLOCK_CLASSNAME = 'me';

const Me: React.FC<Props> = ({ data }: Props) => {
  const { description, profilePicture } = data.contentfulAboutMe;
  const image = getImage(profilePicture);

  if (!image) {
    return <></>;
  }

  return (
    <Layout>
      <div className={BLOCK_CLASSNAME}>
        <div className={`${BLOCK_CLASSNAME}__container`}>
          <ImageWrapper noClick>
            <GatsbyImage image={image} loading="lazy" alt={profilePicture.title} />
          </ImageWrapper>
          <div className={`${BLOCK_CLASSNAME}__content`}>
            <div>
              <div dangerouslySetInnerHTML={{ __html: marked.parse(description.description) }} />
              <br />
              <Button
                onClick={(): Promise<void> => navigate('/contact')}
                variant="contained"
                size="large"
              >
                Kontaktiere mich
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MePage {
    contentfulAboutMe {
      description {
        description
      }
      name
      profilePicture {
        gatsbyImageData
        title
      }
    }
  }
`;

export default Me;
